import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useAddToPickList } from "../hooks/useAddToPickList";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Fade,
  Grid2 as Grid,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSnackBar } from "../hooks/useSnackbar";
import { generatePath, useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { ARTICLE_PAGE_ROUTE } from "../pages/Article/ArticlePage/ArticlePage";
import { useRecoilValue } from "recoil";
import { pickArticleSelector } from "../state/pickState";
import CloseIcon from "@mui/icons-material/Close";
import { scanAPI } from "../api/ScanAPI";
import { CANDY_PICK_PAGE_ROUTE } from "../pages/CandyPickPage";
import { MuiButtonLink } from "./MuiLink";
import { getCandyUnit } from "./UnitPrice";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAddToOrder } from "../hooks/useAddToOrder";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { EditInventoryDialog } from "./EditInventoryDialog";
import { UpdateArticlePendingOrder } from "./UpdateArticlePendingOrder";
import { printQueue, useAddToPrint } from "../api/PrintQueueAPI";
import { LABEL_PRINT_PAGE_BASE_ROUTE } from "../pages/LabelPrintPage";
import { LoadingButton } from "@mui/lab";

interface Props {
  ean: string | undefined;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const ScanInfoSheet: FunctionComponent<Props> = ({ ean, onClose }) => {
  const isOpen = typeof ean !== "undefined";

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <>
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ ean, onClose }} />
        </Box>
      </>
    </SwipeableDrawer>
  );
};

const mockedInventory = import.meta.env.VITE_REACT_APP_MOCK_INVENTORY_QUANTITY;

const InnerSheet: FunctionComponent<Props> = ({ ean, onClose }) => {
  const addToPickList = useAddToPickList();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [inPrintQueue, setInPrintQueue] = useState(false);
  const [noPicture, setNoPicture] = useState<boolean>(false);
  const [isUpdateInventoryDialogOpen, setIsUpdateInventoryDialogOpen] =
    useState(false);
  const [isUpdatePendingOrderDialogOpen, setIsUpdatePendingOrderDialogOpen] =
    useState(false);

  const {
    data: article,
    isError,
    isLoading,
  } = useQuery({
    ...(ean ? scanAPI.fetchArticle(ean) : { queryKey: [] }),
    enabled: typeof ean !== "undefined",
    retry: 1,
  });

  const { mutate: addToOrder } = useAddToOrder();
  const { mutate: addToPrint, isPending: addToPrintPending } = useAddToPrint(() => {
    snackbar.showSnackBar(t("Can not print article"), "error");
  });

  const pickItem = useRecoilValue(pickArticleSelector(article?.articleId));
  const unit = useMemo(() => getCandyUnit(article?.candyType), [article]);

  const pickableQuantity = useMemo(() => {
    if (typeof article === "undefined") {
      return 0;
    }

    if (mockedInventory) {
      return Number(mockedInventory) - (pickItem?.quantity ?? 0);
    }

    return Math.max(
      0,
      article.inventory.currentStock - (pickItem?.quantity ?? 0)
    );
  }, [article, pickItem?.quantity]);

  useEffect(() => {
    if (article?.articleId) {
      printQueue
        .loadInPrintQueue(article.articleId)
        .then((r) => setInPrintQueue(r.pendingPrint));
    }
  }, [article?.articleId]);

  if (typeof article === "undefined" || isLoading) {
    return (
      <div>
        {isError ? (
          <>
            <Typography
              variant="overline"
              component="h3"
              align="center"
              fontSize={24}
              sx={{ my: 2 }}
            >
              {ean}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              sx={{ mb: 4 }}
            >
              {t("Something went wrong, could not find article")}
            </Typography>
            <Button
              type="button"
              fullWidth
              variant="contained"
              size="large"
              onClick={onClose}
            >
              {t("Close")}
            </Button>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    );
  }

  const onPick = (count: number) => {
    if (!article) {
      return;
    }
    addToPickList(
      {
        ean: article.ean,
        articleId: article.articleId,
        displayName: article.displayName,
        candyType: article.candyType,
      },
      count
    );
    snackbar.showSnackBar(
      t("Added {{quantity}} {{article}} to pick list", {
        article: article.displayName,
        quantity: count,
      }),
      "success"
    );
    onClose();
  };

  const onOrder = (count: number) => {
    if (!article) {
      return;
    }

    addToOrder(
      {
        articleId: article.articleId,
        count,
      },
      {
        onSuccess: () => {
          snackbar.showSnackBar(
            t("Added {{quantity}} {{article}} to order", {
              article: article.displayName,
              quantity: count,
            }),
            "success"
          );
        },
        onError: () => {
          snackbar.showSnackBar(
            t("Failed to add {{quantity}} {{article}} to order", {
              article: article.displayName,
              quantity: count,
            }),
            "error"
          );
        },
      }
    );

    onClose();
  };

  const name = article.translation.displayName ?? article.displayName;

  return (
    <>
      <EditInventoryDialog
        count={article.inventory.currentStock}
        articleId={article.articleId}
        onClose={() => setIsUpdateInventoryDialogOpen(false)}
        isOpen={isUpdateInventoryDialogOpen}
        relative
      />

      <UpdateArticlePendingOrder
        displayName={article.displayName}
        articleId={article.articleId}
        price={article.price}
        existingOrder={article.order.pendingOrder}
        isOpen={isUpdatePendingOrderDialogOpen}
        onClose={() => setIsUpdatePendingOrderDialogOpen(false)}
      />

      <Fade in>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            pt: 1,
          }}
        >
          <Typography variant="h5" component="h1" align="center" gutterBottom>
            {name}
          </Typography>
          <Typography
            variant="overline"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <QrCodeIcon sx={{ mr: 1 }} fontSize="small" />
            {article.ean}
          </Typography>
          <Box
            component="img"
            loading="lazy"
            hidden={noPicture}
            onError={() => setNoPicture(true)}
            onLoad={() => setNoPicture(false)}
            src={`https://storage.googleapis.com/candy-pictures/articles/${article.articleId}.jpg`}
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: 240,
              borderRadius: 1,
              objectFit: "contain",
              backgroundColor: "white",
              display: noPicture ? "none" : "block",
            }}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <LoadingButton
              size="large"
              fullWidth
              color={inPrintQueue ? "success" : "primary"}
              disabled={article.candyType === undefined}
              loading={addToPrintPending}
              onClick={() => {
                if (inPrintQueue) {
                  navigate(LABEL_PRINT_PAGE_BASE_ROUTE);
                  return;
                }
                addToPrint(article.articleId);
                setInPrintQueue(true);
              }}
            >
              <LocalPrintshopIcon />
            </LoadingButton>
            <MuiButtonLink
              type="button"
              size="large"
              fullWidth
              to={generatePath(ARTICLE_PAGE_ROUTE, {
                articleId: article.articleId,
              })}
              variant="text"
            >
              <VisibilityRoundedIcon />
            </MuiButtonLink>
          </Box>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid size={6}>
              <Card>
                <CardActionArea
                  onClick={() => setIsUpdateInventoryDialogOpen(true)}
                >
                  <CardContent sx={{ p: 1 }}>
                    <Typography
                      variant="overline"
                      component="h2"
                      align="center"
                    >
                      {t("In inventory")}
                    </Typography>
                    <Typography variant="h4" align="center">
                      {article.inventory.currentStock ?? "-"}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid size={6}>
              <Card>
                <CardActionArea
                  onClick={() => setIsUpdatePendingOrderDialogOpen(true)}
                >
                  <CardContent sx={{ p: 1 }}>
                    <Typography
                      variant="overline"
                      component="h2"
                      align="center"
                    >
                      {t("On order")}
                    </Typography>
                    <Typography variant="h4" align="center">
                      {article.order.pendingOrder ?? "-"}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          {pickItem && (
            <Card
              variant="outlined"
              sx={{ p: 2, flexGrow: 1, mb: 2 }}
              onClick={() => navigate(CANDY_PICK_PAGE_ROUTE)}
            >
              <Typography variant="overline" component="h2" align="center">
                {t("on pick list")}
              </Typography>
              <Typography variant="h4" align="center">
                {pickItem?.quantity ?? 0}
              </Typography>
            </Card>
          )}

          <Grid container spacing={2}>
            {typeof article.disabled !== "undefined" && (
              <Grid size={12}>
                <Alert variant="standard" severity="error" sx={{ mt: 0 }}>
                  {t("This article is no longer available")}
                </Alert>
              </Grid>
            )}
            <Grid
              size={12}
              sx={{
                overflow: "hidden",
              }}
            >
              <CountPicker
                text={t("Add to order")}
                onSelected={(value) => onOrder(value)}
                color="secondary"
                size={article.packageSize}
                unit={unit}
                disabled={!!article.disabled}
              />
            </Grid>
            <Grid
              size={12}
              sx={{
                overflow: "hidden",
              }}
            >
              <CountPicker
                text={t("Add to pick list")}
                onSelected={(value) => onPick(value)}
                color="primary"
                size={article.packageSize}
                max={pickableQuantity}
                unit={unit}
              />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </>
  );
};

interface CountPickerProps {
  onSelected: (value: number) => void;
  text: string;
  color?: "primary" | "secondary";
  size?: number;
  max?: number;
  unit?: string;
  disabled?: boolean;
}

const BUTTON_HEIGHT = 80;

const CountPicker: FunctionComponent<CountPickerProps> = ({
  onSelected,
  text,
  color = "primary",
  size,
  max = 10,
  unit,
  disabled = false,
}) => {
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState<boolean>(true);

  const handleScroll = () => {
    if (!ref.current) {
      return;
    }
    const scrollPosition = ref.current.scrollLeft;
    if (
      scrollPosition < 25 &&
      ref.current.scrollWidth > ref.current.clientWidth
    ) {
      setShowScrollIndicator(true);
    } else {
      setShowScrollIndicator(false);
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const onSelectedInternal = (value: number) => {
    onSelected(value);
    setIsSelecting(false);
  };

  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      {isSelecting ? (
        <Fade appear in={isSelecting} key="count">
          <Grid
            container
            spacing={2}
            flexWrap="nowrap"
            overflow="auto"
            ref={ref}
            component={"div"}
            onScroll={handleScroll}
          >
            <Fade in={showScrollIndicator} timeout={250}>
              <Box
                className="scroll-indicator"
                sx={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  height: "100%",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pr: 1,
                  pointerEvents: "none",
                  minWidth: 24,
                  background: (theme) =>
                    `linear-gradient(to right, transparent 0%, ${
                      theme.palette.mode === "dark"
                        ? "#353535"
                        : theme.palette.background.default
                    } 100%)`,
                }}
              >
                {/* <Fab
                  sx={{
                    background: (theme) => theme.palette.background.default,
                    width: ".4em",
                    height: ".4em",
                    minHeight: "unset",
                    p: 2,
                    pointerEvents: "all",
                  }}
                  onClick={() => {
                    if (!ref.current) {
                      return;
                    }
                    ref.current.scrollBy({
                      left: BUTTON_HEIGHT,
                      behavior: "smooth",
                    });
                  }}
                  size="small"
                  color="default"
                >
                  <ChevronRightIcon />
                </Fab> */}
              </Box>
            </Fade>

            <Grid size="auto">
              <Button
                type="button"
                color="error"
                variant="outlined"
                onClick={() => setIsSelecting(false)}
                sx={{
                  height: BUTTON_HEIGHT,
                  width: BUTTON_HEIGHT,
                }}
              >
                <CloseIcon />
              </Button>
            </Grid>
            {[
              ...Array(max)
                .fill(0)
                .map((_, i) => i + 1),
            ].map((quantity) => (
              <Grid key={quantity} size="auto">
                <Button
                  type="button"
                  variant="contained"
                  color={color}
                  onClick={() => onSelectedInternal(quantity)}
                  sx={{
                    height: BUTTON_HEIGHT,
                    width: BUTTON_HEIGHT,
                    display: "flex",
                    flexDirection: "column",
                    px: 0,
                  }}
                >
                  <Typography variant="h6" component="span">
                    {quantity}
                  </Typography>
                  {size && (
                    <Typography
                      variant="overline"
                      component="span"
                      letterSpacing={0.015}
                    >
                      {roundToTwoDecimals(size * quantity)} {unit}
                    </Typography>
                  )}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Fade>
      ) : (
        <Fade appear in={!isSelecting} key="select">
          <Button
            type="button"
            variant="contained"
            color={color}
            fullWidth
            onClick={() => setIsSelecting(true)}
            sx={{
              height: BUTTON_HEIGHT,
            }}
            startIcon={<AddIcon />}
            disabled={max === 0 || disabled}
          >
            {text}
          </Button>
        </Fade>
      )}
    </Box>
  );
};

const roundToTwoDecimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
