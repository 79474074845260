import { useSuspenseQuery } from "@tanstack/react-query";
import { CandyPage } from "../../components/layout/CandyPage";
import { inventoryAPI } from "../../api/InventoryAPI";
import { ErrorBoundary } from "../../components/layout/ErrorBoundary";
import { Box, Grid2 as Grid, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MuiButtonLink } from "../../components/MuiLink";
import { INVENTORY_LIST_PAGE_ROUTE } from "./InventoryListPage/InventoryListPage";
import HistoryIcon from "@mui/icons-material/History";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { INVENTORY_LOG_PAGE_ROUTE } from "./InventoryLogPage/InventoryLogPage";
import { INVENTORY_NO_STOCK_PAGE_ROUTE } from "./InventoryNoStockPage/InventoryNoStockPage";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { INVENTORY_BULK_ADD_PAGE } from "./InventoryBulkAddPage/InventoryBulkAddPage";
import { INVENTORY_BULK_REMOVE_PAGE_ROUTE } from "./InventoryBulkRemoveStockPage/InventoryBulkRemovePage";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { FormattedPrice } from "../../components/FormattedPrice/FormattedPrice";
import { FeatureCard } from "../../components/Cards/FeatureCard";
import { INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE } from "./InventoryReconciliation/InventoryReconciliationDashboard";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";

export const INVENTORY_DASHBOARD_PAGE_ROUTE = "/inventory";

export const InventoryDashboardPage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Inventory">
      <Inner />
    </CandyPage>
  );
};

const Inner: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorBoundary skeleton={<OverviewSkeleton />}>
        <Overview />
      </ErrorBoundary>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_LIST_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<FormatListBulletedIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("View inventory")}</Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_LOG_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<HistoryIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("View inventory log")}</Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_NO_STOCK_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<EventBusyIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("Back in stock")}</Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<CategoryRoundedIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("Inventory reconciliation")}</Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_BULK_ADD_PAGE}
            variant="contained"
            color="inherit"
            startIcon={<QrCodeScannerIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("Bulk add to inventory")}</Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_BULK_REMOVE_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<QrCodeScannerIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("Bulk remove from inventory")}</Box>
          </MuiButtonLink>
        </Grid>
      </Grid>
    </>
  );
};

const Overview = () => {
  const { t } = useTranslation();

  const { data: overview } = useSuspenseQuery(inventoryAPI.fetchOverview());

  return (
    <Grid container spacing={2} sx={{ my: 0, mb: 2, mt: -1 }}>
      <Grid
        size={{
          xs: 12,
          sm: 4,
        }}
      >
        <FeatureCard
          label={t("Total Worth")}
          value={<FormattedPrice currency="SEK" value={overview.totalWorth} />}
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4,
        }}
      >
        <FeatureCard label={t("Total Boxes")} value={overview.uniqueItems} />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4,
        }}
      >
        <FeatureCard
          label={t("Total Weight")}
          value={overview.totalWeight + " kg"}
        />
      </Grid>
    </Grid>
  );
};

const OverviewSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={70} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={70} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={70} />
        </Grid>
      </Grid>
    </>
  );
};
