import { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import SearchIcon from "@mui/icons-material/Search";
import { generatePath, useNavigate } from "react-router";
import { candyAPI } from "../api/CandyAPI";
import { ARTICLE_PAGE_ROUTE } from "../pages/Article/ArticlePage/ArticlePage";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  open: boolean;
  onClose: () => void;
  isNarrow?: boolean;
}

const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, 0)",
  maxWidth: "500px",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

export const CandySearchModal = ({ open, onClose, isNarrow }: Props) => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce<typeof search>(search, 250);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    setSearch("");
    queryClient.resetQueries({ queryKey: [candyAPI.QUERY_KEY, "search"] });
    onClose();
  };

  const {
    data: results,
    isLoading,
    isFetched,
    isError,
    refetch: triggerSearch,
  } = useQuery({
    ...candyAPI.searchArticle(debouncedSearch),
    enabled: debouncedSearch.length > 0,
    placeholderData: keepPreviousData,
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          ...style,
          ...(isNarrow && {
            transform: "none",
            top: 0,
            width: "100%",
            left: "auto",
            maxWidth: "none",
          }),
        }}
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          triggerSearch();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            p: 2,
          }}
        >
          <InputBase
            fullWidth
            placeholder={t("Search...")}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            autoFocus
            error={isError || results?.length === 0}
            endAdornment={
              <Typography
                variant="overline"
                color="textSecondary"
                sx={{
                  whiteSpace: "nowrap",
                  px: 1,
                }}
              >
                {search && results && results?.length > 0 && (
                  <span>
                    {t("{{quantity}} result(s)", { quantity: results.length })}
                  </span>
                )}
              </Typography>
            }
          />
          {isLoading && (
            <IconButton type="button" sx={{ p: 1, mr: 1 }} aria-label="search">
              <CircularProgress color="inherit" size={24} />
            </IconButton>
          )}
          {!isLoading && (
            <IconButton
              type="button"
              sx={{ p: 1 }}
              onClick={() => {
                triggerSearch();
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Box>
        {isFetched && results?.length === 0 && (
          <>
            <Divider variant="middle" />
            <Typography
              variant="overline"
              align="center"
              color="textSecondary"
              sx={{
                p: 2,
              }}
            >
              {t("No results")}
            </Typography>
          </>
        )}

        <AnimatePresence>
          {debouncedSearch && results && results.length > 0 && (
            <Box
              component={motion.div}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: "auto",
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
              sx={{ overflow: "hidden" }}
            >
              <>
                <Divider variant="middle" />
                <List
                  sx={{
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {results
                  .sort((a,b) => {
                    if(a.disabled && b.disabled) {
                      return 0;
                    }
                    if(a.disabled) {
                      return 1;
                    }
                    if(b.disabled) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((result) => (
                    <ListItemButton
                      divider
                      key={result.articleId}
                      dense
                      onClick={() => {
                        navigate(
                          generatePath(ARTICLE_PAGE_ROUTE, {
                            articleId: result.articleId,
                          })
                        );
                        handleClose();
                      }}
                    >
                      <ListItemText
                        primary={result.name}
                        secondary={result.translationName ?? "-"}
                      />
                      {result.disabled ? (
                        <ListItemIcon>
                          <BlockIcon
                            alignmentBaseline="central"
                            color="error"
                          />
                        </ListItemIcon>
                      ) : null}
                    </ListItemButton>
                  ))}
                </List>
              </>
            </Box>
          )}
        </AnimatePresence>
      </Paper>
    </Modal>
  );
};
