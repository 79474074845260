import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArticleId, CandyType, Nutrients, UTCDate } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

export enum LabelType {
  PRIBOX_300 = "AKRI_PRIBOX_300",
  AKRI_BASIC = "AKRI_BASIC",
}

export interface PrintQueueItem {
  id: number;
  articleId: ArticleId;
  ean: string;
  added: UTCDate;
  addedBy: string;
  displayName: string;
  ingredients: string;
  price?: number;
  type: CandyType;
  nutrients: Nutrients;
  pieceArticle: boolean;
  expiry?: string;
  labelType?: LabelType;
  countryOrigin?: string;
  icons: {
    palmOilFree: boolean;
    gelatinFree: boolean;
    vegan: boolean;
    containsMilk: boolean;
    containsNuts: boolean;
    containsSoy: boolean;
  };
}

export interface InQueueResponse {
  pendingPrint: boolean;
}
export interface UpdateLabelType {
  id: number;
  labelType: LabelType;
}

export const useRemoveFromPrint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: printQueue.removeFromPrint,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["print"] });
    },
  });
};

export const useAddToPrint = (onError: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: printQueue.addToPrint,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["print"] });
    },
    onError,
  });
};

export const useUpdateLabelType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: printQueue.updateLabelType,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["print"] });
    },
  });
};

export const useClearPrintQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: printQueue.clearPrintQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["print"] });
    },
  });
};

export const useFlagAsPrinted = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: printQueue.flagAllAsPrinted,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["print"] });
    },
  });
};

const QUERY_KEY = "print";

export const printQueue = {
  QUERY_KEY: QUERY_KEY,

  fetchQueue: (): RequiredQueryOptions<PrintQueueItem[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<PrintQueueItem[]>(`/print/queue?language=lv`),
  }),
  addToPrint: (articleId: ArticleId) => {
    return API.post("/print/queue", {
      articleId,
      language: "lv",
    });
  },
  fetchInPrintQueue: (
    articleId: ArticleId
  ): RequiredQueryOptions<InQueueResponse> => ({
    queryKey: [QUERY_KEY, articleId],
    queryFn: () =>
      API.get<InQueueResponse>(`/print/queue/${articleId}?language=lv`),
  }),

  loadInPrintQueue: (articleId: ArticleId) =>
    API.get<InQueueResponse>(`/print/queue/${articleId}?language=lv`),

  removeFromPrint: (id: number) => API.delete(`/print/queue/${id}`),
  /**
   * Det här flaggar alla items som printade och tar bort dom från kön
   */
  flagAllAsPrinted: () => API.post("/print/queue/printed"),

  clearPrintQueue: () => API.delete("/print/queue/clear"),

  updateLabelType: (request: UpdateLabelType) =>
    API.post(`/print/queue/${request.id}/label`, {
      labelType: request.labelType,
    }),
};
