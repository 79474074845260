import { CandyArticle } from "../../api/CandyAPI";

export interface CandyLabel {
  candy: CandyArticle;
  translations: {
    productName: string;
    ingredients: string;
  };
}

export const printUtils = {
  existsAndIsNotZero(value: string | undefined) {
    return value !== undefined && value !== "0.0";
  },
  trimZero(value: string | undefined) {
    if (value === undefined) {
      return "";
    }
    return value.replace(".0", "");
  },
};
