import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  Grid2 as Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { candyAPI } from "../../../api/CandyAPI";
import { inventoryAPI } from "../../../api/InventoryAPI";
import { orderAPI } from "../../../api/OrderAPI";
import { ArticleStockHistoryTable } from "../../../components/ArticleStockHistoryTable";
import { CandyPage } from "../../../components/layout/CandyPage";
import { EditInventoryDialog } from "../../../components/EditInventoryDialog";
import { Helmet } from "../../../components/layout/Helmet";
import { PriceChart } from "../../../components/PriceChart";
import { ProviderInventoryChart } from "../../../components/ProviderInventoryChart";
import { getCandyUnit } from "../../../components/UnitPrice";
import { useArticleId } from "../../../hooks/useArticleId";
import { AppTopBarActions } from "../../../components/layout/AppTopBarActions";
import { UpdateArticleDialog } from "./components/UpdateArticleDialog";
import { ArticlePageActions } from "./components/ArticlePageActions";
import {
  FeatureActionCard,
  FeatureCard,
} from "../../../components/Cards/FeatureCard";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { UpdateArticlePendingOrder } from "../../../components/UpdateArticlePendingOrder";
import { FormattedDate } from "../../../components/FormattedDate/FormattedDate";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { CandyTypeChip } from "../../../components/CandyTypeChip";
import { piecePriceAPI } from "../../../api/PiecePriceAPI";
import { ArticleOrderHistoryTable } from "./components/ArticleOrderHistoryTable";

export const ARTICLE_PAGE_ROUTE = "/article/:articleId";

export const ArticlePage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Article" skeleton={<PageSkeleton />}>
      <Inner />
    </CandyPage>
  );
};

const Inner: React.FunctionComponent = () => {
  const articleId = useArticleId();
  const [noPicture, setNoPicture] = useState(false);
  const { t } = useTranslation();
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isUpdateInventoryDialogOpen, setIsUpdateInventoryDialogOpen] =
    useState(false);
  const [isUpdatePendingOrderDialogOpen, setIsUpdatePendingOrderDialogOpen] =
    useState(false);

  const [
    { data: article },
    { data: inventory },
    { data: pendingOrder },
    { data: euroRate },
  ] = useSuspenseQueries({
    queries: [
      candyAPI.fetchArticle(articleId),
      inventoryAPI.fetchInventory(articleId),
      orderAPI.fetchPendingOrder(articleId),
      piecePriceAPI.fetchExchangeRate(),
    ],
  });

  const unit = useMemo(() => getCandyUnit(article?.candyType), [article]);

  const providerStockPrint = (providerStock: number) => {
    if (providerStock < 0) {
      return "0";
    }
    if (providerStock > 100) {
      return "> 100";
    }
    return providerStock;
  };

  if (!article) {
    return null;
  }

  return (
    <>
      <Helmet title={article.productName} />
      <AppTopBarActions>
        <ArticlePageActions
          articleId={articleId}
          ean={article.ean}
          // TODO: validate nutriononal?
          disablePrint={typeof article.candyType === "undefined"}
          onEdit={() => setIsUpdateDialogOpen(true)}
        />
      </AppTopBarActions>

      <UpdateArticleDialog
        isOpen={isUpdateDialogOpen}
        onClose={() => setIsUpdateDialogOpen(false)}
        article={article}
      />

      <EditInventoryDialog
        count={inventory.currentStock}
        articleId={article.articleId}
        onClose={() => setIsUpdateInventoryDialogOpen(false)}
        isOpen={isUpdateInventoryDialogOpen}
      />

      <UpdateArticlePendingOrder
        displayName={article.displayName}
        articleId={article.articleId}
        price={article.ourPrice || 0}
        existingOrder={pendingOrder?.count}
        isOpen={isUpdatePendingOrderDialogOpen}
        onClose={() => setIsUpdatePendingOrderDialogOpen(false)}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {typeof article.disabled !== "undefined" && (
          <Alert variant="filled" severity="error" sx={{ mt: 4 }}>
            {t("This article is no longer available")}
          </Alert>
        )}

        {inventory && (
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FeatureActionCard
                label={t("Current Inventory")}
                icon={<EditRoundedIcon fontSize="small" color="primary" />}
                value={inventory.currentStock ?? "-"}
                onClick={() => setIsUpdateInventoryDialogOpen(true)}
              />
            </Grid>

            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FeatureActionCard
                label={t("Next order")}
                disabled={!!article.disabled}
                value={pendingOrder?.count ?? "-"}
                icon={
                  <EditRoundedIcon
                    fontSize="small"
                    color={article.disabled ? "disabled" : "primary"}
                  />
                }
                onClick={() => setIsUpdatePendingOrderDialogOpen(true)}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FeatureCard
                label={t("Provider Stock")}
                value={providerStockPrint(inventory.providerStock)}
              />
            </Grid>

            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FeatureCard
                label={t("Back in stock")}
                value={
                  inventory.backInStock ? (
                    <FormattedDate value={inventory.backInStock} />
                  ) : (
                    "-"
                  )
                }
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 6,
              sm: 3,
            }}
          >
            <FeatureCard
              label={t("Base price")}
              value={
                <FormattedPrice value={article.basePrice ?? 0} currency="SEK" />
              }
            />
          </Grid>
          <Grid
            size={{
              xs: 6,
              sm: 3,
            }}
          >
            <FeatureCard
              label={t("Our price")}
              value={
                <FormattedPrice value={article.ourPrice ?? 0} currency="SEK" />
              }
            />
          </Grid>

          <Grid
            size={{
              xs: 6,
              sm: 3,
            }}
          >
            <FeatureCard
              label={t("Price per {{unit}}", { unit })}
              value={
                <FormattedPrice
                  value={
                    article.ourPrice && article.packageSize
                      ? article.ourPrice / article.packageSize
                      : 0
                  }
                  currency="SEK"
                />
              }
            />
          </Grid>
          <Grid
            size={{
              xs: 6,
              sm: 3,
            }}
          >
            <FeatureCard
              label={t("Price per {{unit}}", { unit })}
              value={
                <FormattedPrice
                  value={
                    article.ourPrice && article.packageSize && euroRate
                      ? article.ourPrice /
                        article.packageSize /
                        euroRate.sekToEuro
                      : 0
                  }
                  currency="EUR"
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <Box
              component="img"
              loading="lazy"
              hidden={noPicture}
              onError={() => setNoPicture(true)}
              onLoad={() => setNoPicture(false)}
              src={`https://storage.googleapis.com/candy-pictures/articles/${articleId}.jpg`}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 1,
                display: "block",
                objectFit: "contain",
                backgroundColor: "white",
              }}
            />
            {noPicture ? (
              <Paper
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                  alignItems: "center",
                  justifyContent: "center",
                  aspectRatio: 1 / 1,
                  borderRadius: 1,
                }}
              >
                {t("No picture available")}
              </Paper>
            ) : null}
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 8,
            }}
          >
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    size={{
                      xs: 4,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Display name")}
                    </Typography>
                    <Typography variant="body1">
                      {article.displayName}
                    </Typography>
                  </Grid>
                  <Grid
                    size={{
                      xs: 4,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("EAN")}
                    </Typography>
                    <Typography variant="body1">{article.ean}</Typography>
                  </Grid>
                  <Grid
                    size={{
                      xs: 4,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Package size")}
                    </Typography>
                    <Typography variant="body1">
                      {article.packageSize}
                    </Typography>
                  </Grid>
                  <Grid
                    size={{
                      xs: 4,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Type")}
                    </Typography>
                    <Typography variant="body1">
                      <CandyTypeChip candyType={article.candyType} />
                    </Typography>
                  </Grid>
                  <Grid
                    size={{
                      xs: 4,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Origin Country")}
                    </Typography>
                    <Typography variant="body1">
                      {article.countryOrigin ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid
                    size={{
                      xs: 4,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Brand")}
                    </Typography>
                    <Typography variant="body1">
                      {article.brandName ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid
                    size={{
                      xs: 6,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Properties")}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {article.gelatinFree && (
                        <Chip label={t("Gelatin free")} />
                      )}
                      {article.palmOilFree && (
                        <Chip label={t("Palm oil free")} />
                      )}
                      {article.vegan && <Chip label={t("Vegan")} />}
                    </Box>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="h3"
                      color="text.secondary"
                    >
                      {t("Ingredients")}
                    </Typography>
                    <Typography variant="body1">
                      {article.ingredients ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: article.ingredients,
                          }}
                        ></div>
                      ) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="overline" component="h3" gutterBottom>
                {t("Order history")}
              </Typography>
              {article && <ArticleOrderHistoryTable articleId={articleId} />}
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="overline" component="h3" gutterBottom>
                {t("Price history")}
              </Typography>

              {articleId && (
                <PriceChart
                  articleId={articleId}
                  candyType={article.candyType}
                  packageSize={article.packageSize}
                />
              )}
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="overline" component="h3" gutterBottom>
                {t("ERT Stock History")}
              </Typography>

              {articleId && <ProviderInventoryChart articleId={articleId} />}
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="overline" component="h3" gutterBottom>
                {t("Store inventory history")}
              </Typography>
              <ArticleStockHistoryTable articleId={articleId} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <Skeleton variant="rounded" height={100} />
        </Grid>
      </Grid>
      <Skeleton variant="rounded" height={400} sx={{ mt: 4 }} />
    </>
  );
};
