import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ArticleId } from "../../../../api/CandyAPI";
import { SuspenseWrapper } from "../../../../components/layout/SuspenseWrapper";
import { useSuspenseQuery } from "@tanstack/react-query";
import { orderAPI } from "../../../../api/OrderAPI";
import { useState } from "react";
import { FormattedDate } from "../../../../components/date/FormattedDate";
import { FormattedPrice } from "../../../../components/FormattedPrice/FormattedPrice";
import { useTranslation } from "react-i18next";

const DEFAULT_PAGE_SIZE = 5;

interface Props {
  articleId: ArticleId;
}

export const ArticleOrderHistoryTable: React.FunctionComponent<Props> = ({
  articleId,
}) => {
  return (
    <>
      <SuspenseWrapper
        skeleton={
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        }
      >
        <HistoryTable articleId={articleId} />
      </SuspenseWrapper>
    </>
  );
};

const HistoryTable: React.FunctionComponent<Props> = ({ articleId }) => {
  const { t } = useTranslation();
  const { data: orderHistory } = useSuspenseQuery({
    ...orderAPI.fetchOrderedHistory(articleId),
  });

  const [page, setPage] = useState(0);
  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Ordered")}</TableCell>
              <TableCell>{t("Ordered By")}</TableCell>
              <TableCell>{t("Ordered Amount")}</TableCell>
              <TableCell>{t("Ordered Amount")}</TableCell>
              <TableCell>{t("Price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderHistory
              .filter((_, i) => {
                const start = page * DEFAULT_PAGE_SIZE;
                if (i < start) {
                  return false;
                }
                const end = start + DEFAULT_PAGE_SIZE;
                if (i > end) {
                  return false;
                }
                return true;
              })
              .map((row) => (
                <TableRow key={row.orderId}>
                  <TableCell>
                    <FormattedDate date={row.created} />
                  </TableCell>
                  <TableCell>{row.orderedBy}</TableCell>
                  <TableCell>{row.requestedAmount}</TableCell>
                  <TableCell>{row.confirmedAmount}</TableCell>
                  <TableCell>
                    <FormattedPrice value={row.price} currency="SEK" />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={orderHistory.length}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        page={page}
        onPageChange={(_, page) => {
          setPage(page);
        }}
      />
    </>
  );
};
