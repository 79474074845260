import CloseIcon from "@mui/icons-material/Close";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import MarkunreadMailboxRoundedIcon from "@mui/icons-material/MarkunreadMailboxRounded";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TranslateIcon from "@mui/icons-material/Translate";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { TFunction } from "i18next";
import { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo-1.png";
import { useStoreName } from "../../hooks/useStoreName";
import { ARTICLE_LIST_PAGE_ROUTE } from "../../pages/Article/ArticleListPage/ArticleListPage";
import { DASHBOARD_PAGE_ROUTE } from "../../pages/Dashboard/DashboardPage";
import { INVENTORY_DASHBOARD_PAGE_ROUTE } from "../../pages/Inventory/InventoryDashboardPage";
import { ORDER_DASHBOARD_PAGE_ROUTE } from "../../pages/Order/OrderDashboardPage";
import { PIECE_PRICE_PAGE_ROUTE } from "../../pages/PiecePricesPage/PiecePricesPage";
import { TRANSLATION_PAGE_ROUTE } from "../../pages/Translation/TranslationPage";
import { LanguageSelect } from "../LanguageSelect";
import { StoreInfo } from "../StoreInfo";
import { UserInfo } from "../UserInfo";

export const DRAWER_WIDTH = 260;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isNarrow?: boolean;
}

export const AppSidebar: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  isNarrow,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const storeDisplayName = useStoreName();

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
        },
      }}
      variant={isNarrow ? "temporary" : "permanent"}
      anchor="left"
      open={isOpen}
      onClose={onClose}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textDecoration: "none",
          color: "inherit",
          transition: (theme) => theme.transitions.create("background-color"),
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
        component={Link}
        to="/"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            overflow: "hidden",
          }}
        >
          <Box component="img" src={logo} alt="logo" height={"2em"} />
          <Typography variant="overline" noWrap fontWeight={800} lineHeight={1}>
            {storeDisplayName ?? "Candy"}
          </Typography>
        </Box>

        {isNarrow && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <List>
        {LINKS(t).map(({ key, label, icon, matchFn }) => (
          <ListItem
            key={key}
            disablePadding
            onClick={() => {
              onClose();
            }}
          >
            <ListItemButton
              selected={
                matchFn?.(location.pathname) || key === location.pathname
              }
              LinkComponent={Link}
              {...{
                to: key,
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {LINKS_LOWER(t).map(({ key, label, icon, matchFn }) => (
          <ListItem
            key={key}
            disablePadding
            onClick={() => {
              onClose();
            }}
          >
            <ListItemButton
              selected={
                matchFn?.(location.pathname) || key === location.pathname
              }
              LinkComponent={Link}
              {...{
                to: key,
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          marginTop: "auto",
          p: 2,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box>
          <Typography gutterBottom variant="overline" color="textSecondary">
            {t("Active store")}
          </Typography>
          <Box>
            <StoreInfo fullWidth variant="outlined" />
          </Box>
        </Box>
        <Box>
          <Typography gutterBottom variant="overline" color="textSecondary">
            {t("User")}
          </Typography>
          <Box>
            <UserInfo fullWidth variant="outlined" />
          </Box>
        </Box>
        <Box>
          <Typography gutterBottom variant="overline" color="textSecondary">
            {t("Language")}
          </Typography>
          <LanguageSelect />
        </Box>
      </Box>
    </Drawer>
  );
};

enum DisplayFilter {
  DESKTOP,
  MOBILE,
  ALL,
}

interface LinkItem {
  key: string;
  label: string;
  icon: JSX.Element;
  filter: DisplayFilter;
  matchFn?: (pathname: string) => boolean;
}

const LINKS = (t: TFunction): LinkItem[] =>
  [
    {
      key: DASHBOARD_PAGE_ROUTE,
      label: t("Dashboard"),
      icon: <DashboardRoundedIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: ARTICLE_LIST_PAGE_ROUTE,
      label: t("Articles"),
      icon: <FormatListBulletedRoundedIcon />,
      filter: DisplayFilter.ALL,
      matchFn: (pathname: string) =>
        pathname.startsWith(ARTICLE_LIST_PAGE_ROUTE),
    },
    {
      key: INVENTORY_DASHBOARD_PAGE_ROUTE,
      label: "Inventory",
      icon: <InventoryIcon />,
      filter: DisplayFilter.ALL,
      matchFn: (pathname: string) =>
        pathname.startsWith(INVENTORY_DASHBOARD_PAGE_ROUTE),
    },
    {
      key: ORDER_DASHBOARD_PAGE_ROUTE,
      label: t("Orders"),
      icon: <MarkunreadMailboxRoundedIcon />,
      filter: DisplayFilter.ALL,
      matchFn: (pathname: string) =>
        pathname.startsWith(ORDER_DASHBOARD_PAGE_ROUTE),
    },
    // {
    //   key: WEBSHOP_PRODUCTS_PAGE_ROUTE,
    //   label: "Webshop Inventory",
    //   icon: <ShoppingCartIcon />,
    //   filter: DisplayFilter.DESKTOP,
    // },
    {
      key: PIECE_PRICE_PAGE_ROUTE,
      label: "Piece Prices",
      icon: <PointOfSaleIcon />,
      filter: DisplayFilter.DESKTOP,
    },
  ].filter(({ filter }) => {
    if (filter === DisplayFilter.ALL) {
      return true;
    }
    if (isMobile) {
      return filter === DisplayFilter.MOBILE;
    }
    return filter === DisplayFilter.DESKTOP;
  });

const LINKS_LOWER = (t: TFunction): LinkItem[] =>
  [
    {
      key: "/labels",
      label: t("Print Labels"),
      icon: <LocalPrintshopIcon />,
      filter: DisplayFilter.ALL,
    },
    {
      key: TRANSLATION_PAGE_ROUTE,
      label: t("Translations"),
      icon: <TranslateIcon />,
      filter: DisplayFilter.ALL,
      matchFn: (pathname: string) =>
        pathname.startsWith(TRANSLATION_PAGE_ROUTE),
    },
  ].filter(({ filter }) => {
    if (filter === DisplayFilter.ALL) {
      return true;
    }
    if (isMobile) {
      return filter === DisplayFilter.MOBILE;
    }
    return filter === DisplayFilter.DESKTOP;
  });
